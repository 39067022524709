.rtl,
.rtl .country-chooser .menu li,
.rtl .country-chooser a.country-chooser__selector,
.rtl .page-navigation__hotswap h3,
.rtl .page-navigation__hotswap .depth-2,
.rtl .page-navigation__hotswap .depth-1.menu-container--has-nav-tout,
.rtl .hero-block__promo.hero-block__promo--left,
.rtl .hero-block__headline.hero-block__headline--left {
  text-align: right;
}

.rtl .page-utilities__item,
.rtl .page-sticky-footer .page-sticky-footer__left,
.rtl .special-offers-tray .special-offers-tray__col,
.rtl .special-offers-tray .offers-email-signup-form .offers-email-signup-form__email,
.rtl .footer-change-location,
.rtl .page-sticky-footer .page-sticky-footer__left .node-elc-nodeblock,
.rtl .page-utilities-elc-nodeblock,
.rtl .footer-social-links li,
.rtl .language-switcher-elc-l10n-domain,
.rtl #email-signup-page .form-item.form-type-textfield,
.rtl #email-unsubscribe-header-container form labe,
.rtl .mpp__product {
  float: right;
}

.rtl .page-footer .page-sticky-footer h3 {
  float: right;
}

.rtl .page-branding {
  float: right;
  margin-right: 22px;
}

.rtl .page-utilities,
.rtl .product-hero-tout .product-hero-tout-container {
  float: left;
}

.rtl .page-utilities__store-text {
  padding-left: 0;
  padding-right: 10px;
}

.rtl .drawer-formatter__close {
  width: 99%;
  left: 10px;
}

.rtl .back-to-top {
  right: 0px;
  left: 20px;
}

.rtl .page-footer .form-submit {
  margin-left: 0;
  margin-right: 3%;
}

.rtl .footer-links-sitewide-footer-menu-grouping-elc-nodeblock .menu-reference {
  float: right;
  padding: 0 1% 0 3%;
}

.rtl .page-sticky-footer .page-sticky-footer__right {
  left: 10px;
  right: auto;
  width: 256px;
}

.rtl .country-chooser__section {
  padding-right: 0;
}

.rtl .country-chooser__selector {
  background-position: 223% -1218px;
}

.rtl .country-chooser__selector.active {
  background-position: 223% -1281px;
}

.rtl .country-chooser__selector:hover,
.country-chooser__selector.country_chooser-hover {
  background-position: 223% -1281px;
}

.rtl .footer-legal .menu li {
  padding-left: 50px;
  padding-right: 0;
  float: right;
}

.rtl .footer-legal .menu li.last {
  float: left;
  padding-left: 0;
}

.rtl .home-formatter__scroller {
  left: 0;
}

.rtl .language-switcher-elc-l10n-domain li {
  float: right;
}

.rtl .language-switcher-elc-l10n-domain {
  padding-left: 10px;
  padding-right: 19px;
}

.rtl .footer-social-links .facebook {
  margin: 0 0 0 16px;
}

.rtl .page-navigation__hotswap .depth-2 .menu {
  padding-left: 20px;
  padding-right: 0;
}

.rtl .is-alternate:not(.is-search) .page-navigation__menu-toggle {
  left: 0;
  right: auto;
  border-left: 1px solid #cccccc;
  border-right: 0;
}

.rtl .page-navigation .menu-reference:first-child {
  margin-left: 8px;
  margin-right: 0;
}

.rtl .page-navigation .menu-reference:last-child {
  margin-left: 0;
  margin-right: 8px;
}

.rtl .responsive-carousel-nav .js-flex-centered .slides li {
  vertical-align: top;
}

.rtl #contact-us-page .form-item-form-COMMENTS {
  float: left;
  right: 50%;
}

.rtl #contact-us-page .form-type-textfield {
  float: right;
  margin: 0 0 16px 12px;
}

.rtl input[type='checkbox'] ~ label:before,
.rtl input[type='checkbox'] ~ .label:before,
.rtl input[type='radio'] ~ label:before,
.rtl input[type='radio'] ~ .label:before {
  left: auto;
  right: 0;
}

.rtl input[type='checkbox'] ~ label,
.rtl input[type='checkbox'] ~ .label,
.rtl input[type='radio'] ~ label,
.rtl input[type='radio'] ~ .label {
  padding-left: 0;
  padding-right: 24px;
}

.rtl #email-unsubscribe-header-container .form-type-textfield input {
  float: right;
  margin: -7px 10px 0 0;
  padding: 0 14px 0 12px;
}

.rtl #email-unsubscribe-header-container .form-type-textfield {
  float: right;
  margin: 0 0 12px 16px;
}

.rtl #email-unsubscribe-page {
  margin-bottom: 100px;
}
/* 20141215 contact us */
.rtl #contact-us-page .form-radios .form-type-radio {
  padding-right: 24px;
}

.rtl .form-radios .form-type-radio:nth-child(1),
.form-radios .form-type-radio:nth-child(8),
.form-radios .form-type-radio:nth-child(10) {
  padding-right: 0px !important;
}

.rtl #contact-us-page input[type='checkbox'],
input[type='radio'] {
  position: absolute;
  right: -9999px;
}

.rtl #contact-us-page .form-item-form-COMMENTS {
  float: right;
  right: 0px;
}

.rtl #contact-us-page .form-item-form-Headline3 {
  clear: right;
  padding-top: 1px;
}

.rtl .form-item-form-EMAIL-ADDRESS,
.rtl .form-item-form-ADDRESS1,
.rtl .form-item-form-COUNTRY,
.rtl .form-item-form-POSTAL-CODE,
.rtl .form-item-form-NEWLETTERS {
  clear: right !important;
}

.rtl #contact-us-page .form-error {
  padding-right: 100px;
}

.rtl input[type='checkbox'] ~ label::after,
input[type='checkbox'] ~ .label::after,
input[type='radio'] ~ label::after,
input[type='radio'] ~ .label::after {
  left: 0;
  right: 0;
}

.rtl .page-utilities__signup-button {
  padding-left: 22px;
}
/* 20141215store locator */
.rtl.section-store-locator.device-pc .store-locator .local-search__action-links {
  text-align: left;
  padding: 25px 0 20px;
}

.rtl.section-store-locator.device-pc .store-locator .local-search__search-container {
  float: right;
  width: 330px;
  padding-left: 20px;
  padding-right: 0px;
}

.rtl.section-store-locator.device-pc .store-locator .local-search__map-container {
  margin-right: 330px;
  margin-left: 0px;
}

.rtl.section-store-locator.device-pc .store-locator .directions-form__transportation-mode-link {
  text-indent: -119988px;
  overflow: hidden;
  text-align: right;
  text-transform: capitalize;
  display: block;
  float: right;
  width: 71px;
  height: 33px;
  background-color: #f0f0f2;
  background-repeat: no-repeat;
  border-left: solid 1px #cccccc;
}

.rtl.section-store-locator.device-pc .store-locator .directions-form__input-wrapper {
  padding: 0 34px 0 0px;
  margin: 0 0 11px;
}

.rtl.section-store-locator.device-pc .store-locator .directions-form__input-wrapper.directions-form__input-wrapper--a {
  background-size: auto auto;
  background-position: 260px -8707px;
}

.rtl.section-store-locator.device-pc .store-locator .directions-form__input-wrapper.directions-form__input-wrapper--b {
  background-size: auto auto;
  background-position: 260px -8455px;
}

.rtl .selectBox-arrow {
  left: 7px;
  right: auto;
}

.rtl a.selectBox {
  padding-right: 0px;
  padding-left: 25px;
  text-align: right;
}

.rtl .local-search-form__country {
  margin-right: 4px;
}

.rtl .footer-social-links {
  padding-right: 0px;
}

.rtl .footer-legal .menu li.last {
  float: right;
}

.rtl .product-hero-tout .tout__product-copy {
  margin-right: -280px;
}

.rtl .product-hero-tout--style4 .tout__product-copy {
  margin-right: -200px;
}

.rtl input[type='checkbox'],
input[type='radio'] {
  position: absolute;
  right: -9999px;
}
/* 20141216 store locator end */

/* for ipad */
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .rtl .page-navigation__menu-toggle {
    right: 0;
    border-left: 1px solid #cccccc;
  }
  .rtl .is-alternate:not(.is-search) .page-navigation__menu-toggle {
    right: 0;
  }
  .rtl .page-branding {
    margin-right: 110px;
  }
  .rtl .footer-legal .menu li {
    padding-left: 30px;
  }
  .rtl .country-chooser {
    margin-right: 15px;
  }
}
/* end for ipad */

/* 3 minute beauty */
.rtl h1.ffp_header {
  right: 291px;
}

.rtl .tmb_content a {
  padding-right: 45%;
}
/* 3 minute beauty end */
@media only screen and (min-width: 737px) {
  .aerin-carousel-formatter {
    .aerin-slot-button {
      padding: 0 2%;
    }
  }
}
/* Inline css english begin */
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .menu-item-container--childcount-3 {
    :nth-child(1),
    :nth-child(2) {
      max-width: 25%;
    }
    :nth-child(3) {
      max-width: 50%;
    }
  }
}

.page-sticky-footer__right {
  width: 250px\0;
}

.language-switcher-elc-l10n-domain {
  li {
    float: left\0;
  }
  .first {
    padding: 0 15px 0 20px\0;
  }
}

html[data-useragent*='MSIE 8.0'] .mobile_hidden {
  padding-right: 0px;
}

.mobile_hidden {
  padding-right: 35px\9\0;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
  .page-navigation .level-3 {
    margin-bottom: 2px;
  }
  .is-alternate .page-navigation__hotswap {
    padding-top: 10px;
  }
  .page-navigation__hotswap .depth-2 {
    margin-top: 5px;
  }
}
/* Inline CSS english ends */

/* Inline CSS Arabic begins */

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .rtl #email-unsubscribe-page {
    margin-right: 20px;
  }
  .page-footer .page-sticky-footer {
    background: #040a2b;
    min-height: 66px;
  }
  .rtl .country-chooser {
    margin-right: 15px !important;
  }
  .page-footer {
    position: absolute !important;
    bottom: 0;
    min-height: 67px !important;
  }
  #email-signup-page .form-submit {
    float: right;
  }
  .slides li {
    float: right !important;
  }
  .menu-item-container--childcount-3 {
    :nth-child(1),
    :nth-child(2) {
      max-width: 25%;
    }
    :nth-child(3) {
      max-width: 50%;
    }
  }
}

.descscrollbar .scrollbar {
  float: right;
}

.rtl .descscrollbar .scrollbar {
  float: left;
}

.rtl .product_brief__desc2 {
  text-align: right;
}

.product_brief__desc2 {
  text-align: left;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  #email-unsubscribe-header-container .form-builder-container .form-submit {
    margin-top: -10px;
    margin-left: -65px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
  .page-navigation .level-3 {
    margin-bottom: 2px;
  }
  .is-alternate .page-navigation__hotswap {
    padding-top: 10px;
  }
  .page-navigation__hotswap .depth-2 {
    margin-top: 5px;
  }
}
/* fix footer language issue */

.rtl .language-switcher-elc-l10n-domain {
  padding-right: 96px !important;
}
/* fixed top button align */

.back-to-top.back-to-top-show,
.rtl .back-to-top {
  right: 94%;
}

html[data-useragent*='MSIE 8.0'] .page-navigation .menu-item-container {
  max-width: 36%;
}

.page-navigation__menu_text {
  letter-spacing: 0;
}
/* Inline CSS arabic ends */

/* Fixes after adding inline css arabic begins */
.rtl .welcome15-overlay.alt_layout1 .email_popover {
  .email_popover__content {
    width: auto;
  }
  .email_input {
    input.form-text {
      float: right;
      margin-right: 0px;
    }
    padding-right: 0px;
  }
}

rtl input[type='email'],
.form-text {
  letter-spacing: 0;
}

.rtl {
  .welcome15-overlay.alt_layout1 .email_popover .email_input input[type='submit'] {
    margin-left: 10%;
    letter-spacing: 0;
  }
  li {
    .first.leaf.expanded.menu-item-container.menu-item-container--childcount-3,
    .leaf.expanded.menu-item-container.menu-item-container--childcount-3 {
      padding: 0px !important;
    }
  }
}
/* Fixes after adding inline css arabic begins */

.page-sticky-footer .page-sticky-footer__right {
  width: 230px;
  margin-right: 0px;
}

.footer-legal .menu li:nth-child(3n + 3) {
  padding-right: 0px;
}

@media only screen and (min-width: 737px) {
  .rtl .footer-legal {
    padding: 0px;
  }
}

.back-to-top.back-to-top-show {
  right: 20px;
}

.rtl .back-to-top {
  .back-to-top-show {
    right: 94%;
  }
  right: 94%;
}

li.first.leaf.expanded.menu-item-container.menu-item-container--childcount-3 {
  padding: 0px !important;
}

li.leaf.expanded.menu-item-container.menu-item-container--childcount-3 {
  padding: 0px !important;
}

.rtl .product_brief__image {
  background-position: top right;
}

.product_brief__misc-flag-spacer {
  display: block !important;
}

.rtl .button--light {
  letter-spacing: 0 !important;
}

a.selectbox {
  padding-right: 25px;
  padding-left: 0px;
  text-align: left;
}

.rtl.section-store-locator.device-pc .store-locator .store-locator__nav .store-locator__nav-item.active {
  letter-spacing: 0 !important;
}

.rtl.section-store-locator.device-pc .store-locator .store-locator__nav .store-locator__nav-item {
  letter-spacing: 0 !important;
}

.rtl {
  .page-navigation {
    .level-1,
    .level-3 {
      letter-spacing: 0 !important;
    }
  }
  .cta,
  .page-footer,
  h2,
  .headline--large,
  .headline--primary,
  .text-promo,
  .link--bold,
  .estee-edit__header-tags-label,
  .section-store-locator.device-pc .store-locator .local-search__option,
  .form-submit,
  label,
  .section-store-locator.device-pc .store-locator .door-row .door-row__closest,
  .product-hero-tout .tout__product-copy .promo-header-text,
  .button--dark,
  .section-store-locator.device-pc .store-locator .store-locator__nav .store-locator__nav-item,
  .text-links,
  .form-text,
  select {
    letter-spacing: 0 !important;
  }
  a {
    .selectBox,
    .selectbox {
      letter-spacing: 0 !important;
    }
  }
  .button--light {
    letter-spacing: 0 !important;
  }
}

.rtl.section-store-locator.device-pc .store-locator .local-search__option.active {
  letter-spacing: 0 !important;
}

.rtl.section-store-locator.device-pc .store-locator .local-search__option {
  letter-spacing: 0 !important;
}

.rtl a.selectBox {
  letter-spacing: 0 !important;
}

.rtl a.selectbox {
  letter-spacing: 0 !important;
}

.rtl #email-signup-page #edit-form-country {
  width: 91% !important;
}

.rtl {
  .page-sticky-footer .page-sticky-footer__left .node-elc-nodeblock {
    padding-left: 15px;
    padding-right: 0;
  }
  .country-chooser__section {
    padding-left: 15px;
  }
}
